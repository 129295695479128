import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Skeleton,
  Divider,
  Typography,
  Row,
  Col,
  Button,
  Breadcrumb,
  message,
  Grid,
} from 'antd';

import { NotesHeader } from './components/NotesHeader';
import { AboutSection } from './components/About/AboutCompany';
import { AttendeeSection } from './components/Attendees/List';
import { CatchUp } from './components/ContextSources/GetCaughtUp';
import { Research } from './components/CustomResearch/Research';
import { Questions } from './components/Suggestions/Questions';
import { WinrateImages } from '../assets/Images';
import { doPut, getMeetingNotes } from '../sdk/Events';
import { timeAgo } from './utils';
import './MeetingNotes.less';
import { doGet } from '../sdk/Events';
import { MeetingNote, Events, Block } from './MeetingNotesTypes';
import {
  ABOUT_KEY,
  ATTENDEES_KEY,
  CALL_CONTEXT_KEY,
  QUESTIONS_KEY,
  RESEARCH_KEY,
} from './PrepnoteHelper';
import SubscriptionExpiredModal from '../MyAccount/components/Subscription/SubscriptionExpiredModal';

const MAX_ATTEMPTS = 12;

const DEFAULT_BLOCKS = [
  { title: 'About', is_hidden: false, identifier: ABOUT_KEY },
  {
    title: 'Who is coming to the call',
    is_hidden: false,
    identifier: CALL_CONTEXT_KEY,
  },
  { title: 'Get caught up', is_hidden: false, identifier: ATTENDEES_KEY },
  { title: 'Your Research', is_hidden: false, identifier: RESEARCH_KEY },
  { title: 'Questions', is_hidden: false, identifier: QUESTIONS_KEY },
];

let timeoutRef = null as any;

export const MeetingNotes = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const attendeesRef = useRef<HTMLDivElement>(null);
  const caughtUpRef = useRef<HTMLDivElement>(null);
  const researchRef = useRef<HTMLDivElement>(null);
  const questionsRef = useRef<HTMLDivElement>(null);
  const { id = '' } = useParams();
  const [meetingSummary, setMeetingSummary] = useState({} as any);
  const [meetingEventData, setMeetingEventData] = useState({} as any);
  const [meetingNotes, setMeetingNotes] = useState({} as MeetingNote);
  const [updatedTime, setUpdatedTime] = useState({} as Events);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingBlocks, setLoadingBlocks] = useState(true);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [initialUpdatedAt, setInitialUpdatedAt] = useState<string | null>(null);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [showUpdatedTime, setShowUpdatedTime] = useState(false);
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const [showEndSubscriptionModal, setShowEndSubscriptionModal] =
    useState(false);
  const modalOpenedRef = useRef(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const handleScrollToSection = (sectionIdentifier: string) => {
    if (selectedSection !== sectionIdentifier) {
      setSelectedSection(sectionIdentifier);
    }
    let selectedRef = null;

    switch (sectionIdentifier) {
      case ABOUT_KEY:
        selectedRef = aboutRef;
        break;
      case CALL_CONTEXT_KEY:
        selectedRef = caughtUpRef;
        break;
      case ATTENDEES_KEY:
        selectedRef = attendeesRef;
        break;
      case RESEARCH_KEY:
        selectedRef = researchRef;
        break;
      case QUESTIONS_KEY:
        selectedRef = questionsRef;
        break;
      default:
        break;
    }

    if (selectedRef?.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setSelectedSection(sectionIdentifier);
  };

  const updateMeetingNotes = (summary: any) => {
    const mtngnotes = summary;
    if (
      !mtngnotes?.company?.profile?.domain &&
      mtngnotes?.company?.profile?.website
    ) {
      const url = new URL(mtngnotes?.company?.profile?.website);
      mtngnotes.company.profile.domain = url.hostname.replace('www.', '');
    }
    setMeetingNotes(mtngnotes || {});
  };

  const fetchNotes = async (attempt = 0) => {
    setLoading(true);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    try {
      const { data } = await getMeetingNotes(id);
      setMeetingEventData(data);
      updateMeetingNotes(data?.summary);
      if (data?.event?.summary_json) {
        setMeetingSummary(data?.event?.summary_json);
      }

      if (
        ['CREATED', 'IN PROGRESS'].includes(data?.event?.status) &&
        attempt < MAX_ATTEMPTS
      ) {
        setMeetingNotes(data?.summary || {});
        timeoutRef = setTimeout(() => {
          if (attempt < MAX_ATTEMPTS) {
            fetchNotes(attempt + 1);
          }
        }, 2000 * attempt);
        return;
      } else if (
        !data?.summary?.company &&
        data?.event?.status !== 'COMPLETED'
      ) {
        console.error('Error fetching notes:', data?.event?.message);
        setFailed(true);
        return;
      }
      if (!initialUpdatedAt) {
        setInitialUpdatedAt(data?.event?.updated_at);
      }
      setUpdatedTime(data?.event);
      setShowUpdatedTime(initialUpdatedAt !== data?.event?.updated_at);
      setLoading(false);
    } catch (error: any) {
      const { statusCode, body } = error.response;
      if (
        statusCode === 403 &&
        modalOpenedRef.current === false &&
        JSON.parse(body as string).error ===
          'Access denied: Subscription has ended.'
      ) {
        modalOpenedRef.current = true;
        setShowEndSubscriptionModal(true);
      }
      timeoutRef = setTimeout(() => {
        if (attempt < 5) {
          fetchNotes(attempt + 1);
        } else {
          console.error('Error fetching notes:', error);
        }
      }, 2000);
      console.error('Error fetching notes:', error);
    }
  };

  const fetchGeneralTabSetting = async () => {
    setLoadingBlocks(true);
    const path = `setting/general/${userDetails?.userInfo?.id}`;
    try {
      const response = await doGet(path);
      if (response) {
        setBlocks(response.data[0].preferences.blocks);
      } else {
        setBlocks(DEFAULT_BLOCKS);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingBlocks(false);
    }
  };

  const fetchUpdatedNote = async () => {
    setLoading(true);
    try {
      const domains = meetingEventData.summary.enrichedExternalAttendees.map(
        (item: any) => item.attendee.split('@')[1]
      );

      const domain = domains?.[0];
      const payload = {
        start_time: meetingEventData.summary.event.startTime,
        end_time: meetingEventData.summary.event.endTime,
        description: meetingEventData.summary.event.description,
        summary: meetingEventData.summary.event.summary,
        attendees: meetingEventData.summary.event.attendees,
        organizer: meetingEventData.summary.event.organizer,
        meeting_id: meetingEventData.summary.event.meetingId,
        domain,
        meeting_type:
          meetingEventData.summary.enrichedExternalAttendees?.length > 0
            ? 'external'
            : 'internal',
        user_id: meetingEventData.summary.event.userId,
        user_email: meetingEventData.summary.event.userEmail,
        event_summary_id: meetingEventData.event.id,
      };
      const response = await doPut(
        `events/${meetingEventData.event.id}`,
        payload
      );
      if (response.data.summaryId) {
        message.success('Meeting note updated sucessfully');
        await fetchNotes();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchNotes(1), fetchGeneralTabSetting()]);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const firstVisibleBlock = blocks.find((block) => !block.is_hidden);
    if (firstVisibleBlock) {
      setSelectedSection(firstVisibleBlock.identifier);
    }
  }, [blocks]);

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleScroll = debounce(() => {
    const sections = [
      { ref: aboutRef, identifier: ABOUT_KEY },
      { ref: attendeesRef, identifier: ATTENDEES_KEY },
      { ref: caughtUpRef, identifier: CALL_CONTEXT_KEY },
      { ref: researchRef, identifier: RESEARCH_KEY },
      { ref: questionsRef, identifier: QUESTIONS_KEY },
    ];

    const wrapper = document.querySelector('.meeting_notes_wrapper');
    if (wrapper) {
      const scrollTop = wrapper.scrollTop;

      let newSelectedSection = selectedSection;

      sections.forEach(({ ref, identifier }) => {
        if (ref.current) {
          const { offsetTop, clientHeight } = ref.current;
          if (
            offsetTop <= scrollTop + 10 &&
            offsetTop + clientHeight > scrollTop + 10
          ) {
            newSelectedSection = identifier;
          }
        }
      });
      if (newSelectedSection !== selectedSection) {
        setSelectedSection(newSelectedSection);
      }
    }
  }, 100);

  useEffect(() => {
    const wrapper = document.querySelector('.meeting_notes_wrapper');
    if (wrapper) {
      wrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, [blocks]);

  return (
    <div className="meeting_notes_wrapper">
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={7}
          xxl={5}
          className="menu-section summary-points-menu"
        >
          <Breadcrumb separator=">" className="mb-20">
            <Breadcrumb.Item>
              <Link to="/">My meetings</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Prep note</Breadcrumb.Item>
          </Breadcrumb>
          {screens.lg && showUpdatedTime && (
            <Button
              onClick={() => {
                fetchUpdatedNote();
              }}
              className="p-0"
              type="text"
              icon={<img src={WinrateImages.EventIcons.refreshIcon} />}
            >
              <Typography.Text>
                Updated {timeAgo(updatedTime?.updated_at)} ago
              </Typography.Text>
            </Button>
          )}

          {screens.lg ? (
            loadingBlocks ? (
              <Skeleton
                active
                paragraph={{ rows: 5 }}
                style={{ marginTop: '15px' }}
              />
            ) : (
              <div className="summary-title">
                {blocks.map((block, index) =>
                  !block.is_hidden ? (
                    <Typography.Text
                      key={index}
                      className={`summary-points ${
                        selectedSection === block.identifier ? 'Active' : ''
                      }`}
                      onClick={() => handleScrollToSection(block.identifier)}
                    >
                      {block.title}
                      {block.identifier === ABOUT_KEY &&
                      meetingNotes?.company?.profile?.name
                        ? ` - ${meetingNotes?.company?.profile?.name}`
                        : ''}
                    </Typography.Text>
                  ) : null
                )}
              </div>
            )
          ) : null}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={17}
          xxl={20}
          className="content-section"
        >
          {!loading && failed && (
            <div>
              <h1>Failed to fetch meeting notes</h1>
              <p>
                There was an error fetching the meeting notes. Please try again
                later.
              </p>
            </div>
          )}

          {!failed && (
            <div>
              <NotesHeader
                summary={meetingSummary?.summary || meetingNotes?.summary}
                startTime={
                  meetingSummary?.start_time || meetingNotes?.startTime
                }
                endTime={meetingSummary?.end_time || meetingNotes?.endTime}
                domain={meetingSummary?.domain || meetingNotes?.event?.domain}
              />

              <Divider style={{ background: '#f5f5f5' }} />

              <div className="note-body-section">
                {(blocks || []).map((block) => {
                  if (block.is_hidden) return null;
                  return (
                    <>
                      {block.identifier === ABOUT_KEY && (
                        <div ref={aboutRef} key="about">
                          <AboutSection
                            loading={loading}
                            title={block?.title}
                            company={meetingNotes?.company}
                            about={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.about || {}
                            }
                            news={
                              meetingNotes?.aiSummary?.generated_schema?.news ||
                              []
                            }
                            crmCompany={
                              meetingNotes?.crmNotes?.companies?.[
                                meetingNotes?.event?.domain
                              ]
                            }
                            crmId={meetingNotes?.crmNotes?.portalId}
                            crmProvider={meetingNotes?.crmNotes?.provider}
                          />
                          <Divider style={{ background: '#f5f5f5' }} />
                        </div>
                      )}

                      {block.identifier === ATTENDEES_KEY && (
                        <div ref={attendeesRef} key="who-is-coming">
                          <AttendeeSection
                            loading={loading}
                            title={block?.title}
                            attendees={meetingNotes?.enrichedExternalAttendees}
                            contacts={meetingNotes?.crmNotes?.contacts || {}}
                            crmId={meetingNotes?.crmNotes?.portalId}
                            crmProvider={meetingNotes?.crmNotes?.provider}
                          />
                          <Divider style={{ background: '#f5f5f5' }} />
                        </div>
                      )}

                      {block.identifier === CALL_CONTEXT_KEY && (
                        <div
                          ref={caughtUpRef}
                          className="note-context"
                          key="get-caught-up"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          <CatchUp
                            loading={loading}
                            title={block?.title}
                            summary={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.callSetup?.summary || ''
                            }
                            emailNotes={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.emailNotes || ''
                            }
                            crmNotes={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.crmNotes || ''
                            }
                            crmProvider={meetingNotes?.crmNotes?.provider || ''}
                            transcriptNotes={
                              meetingNotes?.transcriptNotes || ''
                            }
                          />
                          <Divider style={{ background: '#f5f5f5' }} />
                        </div>
                      )}

                      {block.identifier === RESEARCH_KEY && (
                        <div ref={researchRef} key="your-research">
                          <Research
                            loading={loading}
                            title={block?.title}
                            researchItems={meetingNotes?.research || []}
                          />
                          <Divider style={{ background: '#f5f5f5' }} />
                        </div>
                      )}

                      {block.identifier === QUESTIONS_KEY && (
                        <div ref={questionsRef} key="questions">
                          <Questions
                            loading={loading}
                            title={block?.title}
                            questions={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.suggestedQuestions || []
                            }
                          />
                          <Divider style={{ background: '#f5f5f5' }} />
                        </div>
                      )}
                    </>
                  );
                })}

                {(!blocks || blocks.length === 0) && (
                  <>
                    <div ref={aboutRef}>
                      <AboutSection
                        loading={loading}
                        company={meetingNotes?.company}
                        about={
                          meetingNotes?.aiSummary?.generated_schema?.about || {}
                        }
                        news={
                          meetingNotes?.aiSummary?.generated_schema?.news || []
                        }
                        crmCompany={
                          meetingNotes?.crmNotes?.companies?.[
                            meetingNotes?.event?.domain
                          ]
                        }
                        crmId={meetingNotes?.crmNotes?.portalId}
                        crmProvider={meetingNotes?.crmNotes?.provider}
                      />
                      <Divider style={{ background: '#f5f5f5' }} />
                    </div>
                    <div ref={attendeesRef}>
                      <AttendeeSection
                        loading={loading}
                        attendees={meetingNotes?.enrichedExternalAttendees}
                        contacts={meetingNotes?.crmNotes?.contacts || {}}
                        crmId={meetingNotes?.crmNotes?.portalId}
                        crmProvider={meetingNotes?.crmNotes?.provider}
                      />
                      <Divider style={{ background: '#f5f5f5' }} />
                    </div>
                    <div
                      ref={caughtUpRef}
                      className="note-context"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <CatchUp
                        loading={loading}
                        summary={
                          meetingNotes?.aiSummary?.generated_schema?.callSetup
                            ?.summary || ''
                        }
                        emailNotes={
                          meetingNotes?.aiSummary?.generated_schema
                            ?.emailNotes || ''
                        }
                        crmNotes={
                          meetingNotes?.aiSummary?.generated_schema?.crmNotes ||
                          ''
                        }
                        crmProvider={meetingNotes?.crmNotes?.provider || ''}
                        transcriptNotes={meetingNotes?.transcriptNotes || ''}
                      />
                      <Divider style={{ background: '#f5f5f5' }} />
                    </div>
                    <div ref={researchRef}>
                      <Research
                        loading={loading}
                        researchItems={meetingNotes?.research || []}
                      />
                      <Divider style={{ background: '#f5f5f5' }} />
                    </div>
                    <div ref={questionsRef}>
                      <Questions
                        loading={loading}
                        questions={
                          meetingNotes?.aiSummary?.generated_schema
                            ?.suggestedQuestions || []
                        }
                      />
                      <Divider style={{ background: '#f5f5f5' }} />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <SubscriptionExpiredModal
        setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        showEndSubscriptionModal={showEndSubscriptionModal}
        users={userDetails?.userInfo?.id}
      />
    </div>
  );
};
